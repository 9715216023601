import React, { useContext } from "react";
import { LanguageContext } from "../context/LanguageContext";
import translations from "../data/langData";

const PrivacePolicy = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  return (
    <>
      <section className="privacy my-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-11">
              <h1  className="privacyh1" style={{fontSize:"30px",fontWeight:"600",fontFamily:"Montserrat"}} >{t.privace.toUpperCase()}</h1>
              <h6
                dangerouslySetInnerHTML={{ __html: t.privacyDesc }}
              />
              <h1 className="mt-2 privacyh1" style={{fontSize:"30px",fontWeight:"600",fontFamily:"Montserrat"}}>{t.privacy1.toUpperCase()}</h1>
              <h6
                className=""
                dangerouslySetInnerHTML={{ __html: t.privacyDesc1 }}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PrivacePolicy;
