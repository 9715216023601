import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import slugify from "slugify";
import { LanguageContext } from "../../context/LanguageContext";
import translations from "../../data/langData";
import LazyLoad from "react-lazy-load";

const ShopByCategories = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const [data, setData] = useState([]);
  const [cat, setCat] = useState([]);
  const uniqueParents = new Set();
  useEffect(() => {
    Promise.all([
      axios.get("https://myshops.az/apk/app_prod.php?q=vertu"),
      axios.get("https://myshops.az/apk/app_cat.php"),
    ]).then(([dataRes, catRes]) => {
      const data = dataRes.data;
      const cat = catRes.data;
      setData(data);
      setCat(cat);
    });
  }, [language]);
  return (
    <>
      <section className="pb-5">
        <div className="container-fluid">
          <div className="flat-title wow fadeInUp" data-wow-delay="0s">
            <span className="title">{t.categories}</span>
          </div>
          <div className="hover-sw-nav">
            <div className="swiper tf-sw-collection">
              <div>
                <div className="row justify-content-center">
                  {cat.map((i,index) => {
                    const findCat = data.find(
                      (catId) => catId.category == i.id
                    );
                    if (findCat) {
                      const parentId = cat.find(
                        (catItem) => catItem.id == findCat.parent
                      );
                      if (parentId) {
                        if (!uniqueParents.has(parentId.id)) {
                          uniqueParents.add(parentId.id);
                          const findData = data.filter(
                            (item) => item.parent == parentId.id
                          );
                          return (
                            <div className="col-lg-2 col-md-3 col-sm-6 col-12" key={index}>
                              <div className="swiper-slide" lazy="true">
                                <div className="collection-item  style-2 hover-img">
                                  <div className="collection-inner">
                                    <NavLink to={`/${slugify(parentId.name_az).toLowerCase()}`}
                                      className="collection-image img-style rounded-0"
                                    >
                                      {
                                        findData.slice(0,1).map((item,index)=>(
                                        <LazyLoad>
                                              <img
                                        key={index}
                                        className="lazyload aboutDesc"
                                        data-src={item.icon}
                                        src={item.icon}
                                        alt="collection-img"
                                            />
                                        </LazyLoad>
                                        ))
                                      }
                                    </NavLink>
                                    <div className="collection-content">
                                      <NavLink to={`/${slugify(parentId.name_az).toLowerCase()}`}
                                        className="tf-btn collection-title hover-icon fs-15"
                                      >
                                        <span className="aboutDesc">{language === "az" ? parentId.name_az : parentId.name_ru}</span>
                                        <i className="icon icon-arrow1-top-left" />
                                      </NavLink>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        }
                      }
                    }
                  })}
                  {/* {
                cat.map((i)=>(
                    <div className="col-2 bg-primary">
                    <div className="swiper-slide" lazy="true">
                      <div className="collection-item style-2 hover-img">
                        <div className="collection-inner">
                          <a
                            href="shop-collection-sub.html"
                            className="collection-image img-style rounded-0"
                          >
                            <img
                              className="lazyload"
                              data-src="asset/images/collections/collection-29.jpg"
                              src="asset/images/collections/collection-29.jpg"
                              alt="collection-img"
                            />
                          </a>
                          <div className="collection-content">
                            <a
                              href="shop-collection-sub.html"
                              className="tf-btn collection-title hover-icon fs-15"
                            >
                              <span>Accessories</span>
                              <i className="icon icon-arrow1-top-left" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    </div>
                ))
             } */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ShopByCategories;
