import React, { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import logo from "../assets/image/logowhite.png";
import { LanguageContext } from "../context/LanguageContext";
import translations from "../data/langData";
import axios from "axios";
import slugify from "slugify";
const Footer = () => {
  const uniqueParents = new Set();

  const date = new Date();
  const currentYear = date.getFullYear();
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const [data, setData] = useState([]);
  const [cat, setCat] = useState([]);
  useEffect(() => {
    Promise.all([
      axios.get("https://myshops.az/apk/app_prod.php?q=vertu"),
      axios.get("https://myshops.az/apk/app_cat.php"),
    ]).then(([dataRes, catRes]) => {
      const data = dataRes.data;
      const cat = catRes.data;
      setData(data);
      setCat(cat);
    });
  }, [language]);
  return (
    <>
      <footer id="footer" className="footer background-black">
        <div className="footer-wrap wow fadeIn" data-wow-delay="0s">
          <div className="footer-body">
            <div className="container">
              <div className="row">
                <div className="col-xl-3 col-md-6 col-12">
                  <div className="footer-infor">
                    <div className="footer-logo">
                      <NavLink
                        to="/"
                        onClick={() => window.scrollTo({ top: 0 })}
                      >
                        <img src={logo} alt="footer logo" className="aboutDesc" />
                      </NavLink>
                    </div>
                    <ul>
                      <li>
                        <p className="aboutDesc">{t.addresscontact}</p>
                      </li>
                      <li>
                        <p className="aboutDesc">
                          {t.email}:{" "}
                          <a href="mailto:contact@vertuz.com" className="aboutDesc">
                            contact@vertuaz.com
                          </a>
                        </p>
                      </li>
                      <li>
                        <p className="aboutDesc">
                          {t.phone}:{" "}
                          <a href="tel:+994508260099" className="a_tel aboutDesc">
                            +994508260099
                          </a>
                        </p>
                      </li>
                    </ul>
                    <ul className="tf-social-icon d-flex gap-10 style-white">
                      <li>
                        <a
                          href="https://www.instagram.com/vertu_official_azerbaijan/"
                          target="_blank"
                          className="box-icon w_34 round social-instagram social-line"
                        >
                          <i className="icon fs-14 icon-instagram" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-2 col-6 text-white">
                  <h6 className="text-white aboutDesc">{t.collections1}</h6>
                  {cat.map((i,index) => {
                    const findCat = data.find(
                      (catId) => catId.category == i.id
                    );
                    if (findCat) {
                      const parentId = cat.find(
                        (catItem) => catItem.id == findCat.parent
                      );
                      if (parentId) {
                        if (
                          !uniqueParents.has(parentId.id) &&
                          parentId.name_az !== "Kabel"
                        ) {
                          return (
                            <div className="mega-menu-item" key={index}>
                              <div className="menu-heading">
                                <NavLink
                                  to={`/${slugify(
                                    parentId.name_az
                                  ).toLowerCase()}`}
                                  className="my-2"
                                  onClick={() => window.scrollTo({ top: 0 })}
                                >
                                  <h6
                                    style={{ fontSize: "18px" }}
                                    className="footerCat aboutDesc"
                                  >
                                    {language == "az"
                                      ? parentId.name_az
                                      : parentId.name_ru}
                                  </h6>
                                </NavLink>
                              </div>
                            </div>
                          );
                        }
                      }
                    }
                  })}
                </div>
                <div className="col-lg-2 col-6 text-white">
                  <h6 className="text-white aboutDesc">{t.pages}</h6>
                  <NavLink
                    to="/about"
                    className=""
                    onClick={() => window.scrollTo({ top: 0 })}
                  >
                    <h6 className="text-white  footerh6 my-2 aboutDesc">{t.about1}</h6>
                  </NavLink>
                  <NavLink
                    to="/contact"
                    className=""
                    onClick={() => window.scrollTo({ top: 0 })}
                  >
                    <h6 className="text-white  footerh6 my-2 aboutDesc">{t.contact1}</h6>
                  </NavLink>
                  <NavLink
                    to="/terms-of-use"
                    onClick={() => window.scrollTo({ top: 0 })}
                  >
                    <h6 className="text-white  footerh6 my-2 aboutDesc">{t.privace}</h6>
                  </NavLink>
                </div>
                <div className="col-lg-5  col-12 text-white">
                  <iframe
                    className="map aboutDesc"
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12155.824538011233!2d49.8915052!3d40.3876648!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40307d5d530f9761%3A0xca5ecf034265485d!2zQcSfIFNhcmF5IFJlc2lkZW5jZSwgUWFyYWJhxJ8gQXRsYXLEsSBtZXlkYW7EsQ!5e0!3m2!1str!2saz!4v1723700437786!5m2!1str!2saz"
                    width="100%"
                    height={"80%"}
                    style={{ border: "0" }}
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                  <p className="text-end aboutDesc">{t.addresscontact}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="footer-bottom-wrap d-flex gap-20 flex-wrap justify-content-center align-items-center">
                    <div className="footer-menu_item aboutDesc" style={{ color: "#fff" }}>
                      © Copyright {currentYear}{" "}
                      <NavLink
                        to="/"
                        onClick={() => window.scrollTo({ top: 0 })}
                        className="aboutDesc"
                      >
                        <font color="yellow" className="aboutDesc">Vertuaz.com</font>{" "}
                      </NavLink>{" "}
                      by My Group. {t.copyright}{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
